// This file is part of Materials Cloud Archive
// Copyright (C) 2025 Materials Cloud Team.

import React, { Component } from "react";
import { Input, ArrayField, SelectField } from "react-invenio-forms";
import { Grid, GridRow, GridColumn, Form, Button, Icon } from "semantic-ui-react";

export class MCreferences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenShown: false,
    };
  }

  render() {
    const {
      fieldPath, // injected by the custom field loader via the `field` config property
      ref_resource_type,
      ref_citation,
      ref_comment,
      ref_link,
      addButtonLabel,
      label,
      showEmptyValue,
      required,
    } = this.props;

    const defaultNewValue = {
      ref_resource_type: "",
      ref_citation: "",
      ref_link: "",
      ref_comment: "",
    };

    return (
      <>
        <label htmlFor={fieldPath}  className="helptext" style={{ marginBottom: "10px" }}>
          References associated with the record, e.g. scientific publications where the data is described, etc.<br/>
          If the data in this record are used in the Explore, Discover or Work/Tools sections of Materials Cloud, please add the links in 'Materials Cloud sections using these data'.<br/>
          Once the record is published, you can still update the references.
        </label>
        <ArrayField
          addButtonLabel={addButtonLabel}
          defaultNewValue={defaultNewValue}
          fieldPath={fieldPath}
          label={label}
          required={required}
          showEmptyValue={showEmptyValue}
        >
          {({ arrayHelpers, indexPath, value, array }) => {
            const fieldPathPrefix = `${fieldPath}.${indexPath}`;

            // this is needed to display the first element of array with empty default values
            if (array.length == 1 && value.__key == 0 && !this.state.hasBeenShown) {
              // there are no values in references, add default
              value.__key = -1;
              arrayHelpers.insert(0, defaultNewValue);
              this.state.hasBeenShown = true;
            } else {
              // there are already values in references
              this.state.hasBeenShown = true;
            }

            return (
              <>
                <Grid id="mc_references">
                  <GridRow columns='equal'>
                    <GridColumn>
                      <SelectField
                        clearable
                        fieldPath={`${fieldPathPrefix}.ref_resource_type`}
                        label={ref_resource_type.label}
                        placeholder={ref_resource_type.placeholder}
                        description={ref_resource_type.description}
                        options={ref_resource_type.options}
                        optimized
                        required
                      />
                    </GridColumn>
                    <GridColumn width={12}>
                      <Input
                        fieldPath={`${fieldPathPrefix}.ref_citation`}
                        label={ref_citation.label}
                        placeholder={ref_citation.placeholder}
                        description={ref_citation.description}
                        required
                      ></Input>
                    </GridColumn>
                  </GridRow>

                  <GridRow columns='equal'>
                    <GridColumn>
                      <SelectField
                        clearable
                        fieldPath={`${fieldPathPrefix}.ref_link.ref_scheme`}
                        label={ref_link["ref_scheme"].label}
                        placeholder={ref_link["ref_scheme"].placeholder}
                        description={ref_link["ref_scheme"].description}
                        options={ref_link["ref_scheme"].options}
                        optimized
                      />
                    </GridColumn>
                    <GridColumn width={12}>
                      <Input
                        fieldPath={`${fieldPathPrefix}.ref_link.ref_identifier`}
                        label={ref_link["ref_identifier"].label}
                        placeholder={ref_link["ref_identifier"].placeholder}
                        description={ref_link["ref_identifier"].description}
                      ></Input>
                    </GridColumn>
                  </GridRow>

                  <GridRow columns='equal'>
                    <GridColumn width={12}>
                      <Input
                        fieldPath={`${fieldPathPrefix}.ref_comment`}
                        label={ref_comment.label}
                        placeholder={ref_comment.placeholder}
                        description={ref_comment.description}
                      ></Input>
                    </GridColumn>
                    <GridColumn style={{"display": "grid", "alignItems": "center", "float": "right"}}>
                      <Form.Field>
                        <Button
                          aria-label={"Remove field"}
                          className="close-btn"
                          icon
                          onClick={() => arrayHelpers.remove(indexPath)}
                          type="button"
                          id="remove_reference"
                          style={{"float": "right"}}
                        >
                        <Icon name="close" />
                        </Button>
                      </Form.Field>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </>
            );
          }}
        </ArrayField>
      </>
    );
  }
}
